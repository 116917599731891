import React, { useState } from 'react';

import SvgPlus from '../assets/images/vectors/icons/plus.inline.svg';

const PanelFaq = ({ title, children }) => {
  const [isOpenPanel, setTogglePanel] = useState(false);

  return (
    <div className={`common__faq ${isOpenPanel ? 'common__faq--open' : ''}`}>
      <div className="common__faq__title" onClick={() => setTogglePanel(!isOpenPanel)}>
        <SvgPlus />
        <span>{title}</span>
      </div>

      <div className="common__faq__content">{children}</div>
    </div>
  );
};

export default PanelFaq;
