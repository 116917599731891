import React from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PanelFaq from '../components/CommonPanelFaq';

import '../assets/styles/pages/contact-us-page.scss';
import { isClient } from '../helpers';

const Page = ({ data }) => {
  const prismicData = data.prismic.allContact_us_pages.edges[0].node;

  if (isClient) {
    navigate('/'); // TODO: temporary disable this page
  }

  return <div />;
  // return ( // TODO: temporary disable this page
  //   <Layout>
  //     <SEO title="Contact Us" />
  //     <div className="contact-us-page">
  //       <FirstSection data={data} prismicData={prismicData} />
  //       {/*<SecondSection prismicData={prismicData} />*/}
  //     </div>
  //   </Layout>
  // );
};

const ContactCards = ({ data }) => (
  <>
    <div className="c-card">
      <img src={data[0].icon.url} alt="" />
      <div className="c-h5--bold">{RichText.asText(data[0].title)}</div>
      <p>{RichText.asText(data[0].description)}</p>
      <a href={data[0].link.url} target="_blank" rel="noopener noreferrer" className="c-btn">
        Get help
      </a>
    </div>
    <div className="c-card">
      <img src={data[1].icon.url} alt="" />
      <div className="c-h5--bold">{RichText.asText(data[1].title)}</div>
      <p>{RichText.asText(data[1].description)}</p>
      <a href={data[1].link.url} className="c-btn">
        Contact
      </a>
    </div>
    <div className="c-card">
      <img src={data[2].icon.url} alt="" />
      <div className="c-h5--bold">{RichText.asText(data[2].title)}</div>
      <p>{RichText.asText(data[2].description)}</p>
      <a href={data[2].link.url} className="c-btn">
        Get in touch
      </a>
    </div>
  </>
);

const FirstSection = ({
  data: { imageFirstSection },
  prismicData: { first_section_title, first_section_cards },
}) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div className="cfs__l__wrapper">
              <h1 className="c-h1 cfs__l__title">{RichText.render(first_section_title)}</h1>
              <div className="cfs__l__cards">
                <ContactCards data={first_section_cards} />
              </div>
            </div>
          </div>
          <div className="cfs__r">
            <Img fluid={imageFirstSection.childImageSharp.fluid} />
          </div>
        </div>
        <div className="cfs__cards">
          <ContactCards data={first_section_cards} />
        </div>
      </div>
    </section>
  </section>
);

const SecondSection = ({ prismicData: { faqTitle, faqPanel } }) => (
  <section className="c-section-my second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-11 col-lg-10 mx-auto">
          <div className="c-h1">{RichText.asText(faqTitle)}</div>
          <div className="second-section__wrapper">
            {faqPanel.map((item, index) => (
              <PanelFaq title={item.title ? RichText.asText(item.title) : ''} key={index}>
                {RichText.render(item.description)}
              </PanelFaq>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Page;

export const query = graphql`
  query {
    prismic {
      allContact_us_pages {
        edges {
          node {
            first_section_title
            first_section_cards {
              title
              description
              icon
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            faqTitle
            faqPanel {
              title
              description
            }
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/contact-us/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
